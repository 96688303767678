import {
  ITimesheetPagination,
  ITimesheetResponse,
} from "@/Interfaces/Timesheet.interface";
import { IUser } from "@/Interfaces/User.interface";
import { RootState } from "@/Store";
import Utils from "@/Utils";
import { Icon } from "@chakra-ui/react";
import dayjs from "dayjs";
import _ from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Button } from "../Common";
import saveAs from "file-saver";
import ExcelJS from "exceljs";
import { DownloadIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-multi-lang";

interface IDayData {
  id: string;
  date: string;
  status: string;
}

interface IDataStructure {
  id: string;
  name: string;
  avatar: string;
  days: IDayData[];
  workAtHome: number;
  totalWorkingDays: number;
  user?: any;
}

const headerExceljs = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "AA",
  "AB",
  "AC",
  "AD",
  "AE",
  "AF",
  "AG",
  "AH",
  "AI",
];

const ExportExcelAttdance = () => {
  const t = useTranslation();
  const userData = Utils.getSavedUserData();
  const timesheets = useSelector((state: RootState) =>
    _.get(state.TIMESHEET, "payload")
  );

  const users: IUser[] = useSelector((state: RootState) =>
    _.get(state.USER, "userList")
  );
  const pagination: ITimesheetPagination = useSelector((state: RootState) =>
    _.get(state.TIMESHEET, "pagination")
  );

  const canUpdateTimesheet = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Timesheet",
    "updateTimesheet"
  );

  const startDate = dayjs(pagination?.startDate);

  const daysInMonth = Array.from(
    { length: startDate.daysInMonth() },
    (_, index) =>
      startDate.startOf("month").add(index, "day").format("YYYY-MM-DD")
  );

  const transformData = (
    timesheets: ITimesheetResponse[],
    users: IUser[]
  ): IDataStructure[] => {
    const data: IDataStructure[] = [];
    const tickValueMapping = {
      X: 1,
      "K/2": 0.5,
      K: 0,
      "P/2": 1,
      P: 1,
      L: 1,
      TV: 1,
    };
    // Iterate through users to create the transformed data
    if (users?.length > 0 && canUpdateTimesheet) {
      _.forEach(users, (user) => {
        // Skip users with 'admin' role
        // Filter by pagination
        const userId = user.id;

        if (!_.isEmpty(pagination?.roleCode)) {
          const userRoles = _.map(
            user?.userRole,
            (userRole) => userRole?.role?.roleCode
          );
          if (
            !_.some(pagination?.roleCode, (roleCode) =>
              _.includes(userRoles, roleCode)
            )
          )
            return;
        }

        if (
          !_.isEmpty(pagination?.userId) &&
          !_.includes(pagination?.userId, userId)
        ) {
          return;
        }

        if (
          !_.isEmpty(pagination?.keyword) &&
          !_.includes(user?.userData?.fullName, pagination?.keyword)
        ) {
          return;
        }

        const employee = _.find(timesheets, { id: userId });
        // Check if the user is in the timesheet, otherwise use user data
        const userItem: IUser = employee ? { ...user, ...employee } : user;
        const employeeData: IDataStructure = {
          id: userId,
          name: userItem?.userData?.fullName,
          avatar: userItem?.userData?.avatar?.path,
          days: [],
          workAtHome: 0,
          totalWorkingDays: 0,
          user: userItem,
        };

        // If user is in timesheet, iterate through timesheet entries
        if (userItem?.timesheet) {
          _.forEach(userItem?.timesheet, (entry) => {
            const dayData: IDayData = {
              id: entry.id,
              date: dayjs(entry.date).format("YYYY-MM-DD"),
              status: entry.tick,
            };

            if (entry.workFromHome && entry?.workFromHomeAfternoon) {
              employeeData.workAtHome += 1;
            }
            if (entry.workFromHome && !entry?.workFromHomeAfternoon) {
              employeeData.workAtHome += 0.5;
            }
            if (!entry.workFromHome && entry?.workFromHomeAfternoon) {
              employeeData.workAtHome += 0.5;
            }

            employeeData.days.push(dayData);
            employeeData.totalWorkingDays +=
              tickValueMapping[entry.tick as keyof typeof tickValueMapping] ||
              0;
          });
        }
        data.push(employeeData);
      });
    } else {
      _.forEach(timesheets, (employee) => {
        const employeeData: IDataStructure = {
          id: employee.id,
          name: employee.userData.fullName,
          avatar: employee.userData?.avatar?.path,
          days: [],
          workAtHome: 0,
          totalWorkingDays: 0,
          user: employee,
        };

        _.forEach(employee.timesheet, (entry) => {
          const dayData: IDayData = {
            id: entry.id,
            date: dayjs(entry.date).format("YYYY-MM-DD"),
            status: entry.tick,
          };
          if (entry.workFromHome && entry?.workFromHomeAfternoon) {
            employeeData.workAtHome += 1;
          }
          if (entry.workFromHome && !entry?.workFromHomeAfternoon) {
            employeeData.workAtHome += 0.5;
          }
          if (!entry.workFromHome && entry?.workFromHomeAfternoon) {
            employeeData.workAtHome += 0.5;
          }
          employeeData.days.push(dayData);
          employeeData.totalWorkingDays +=
            tickValueMapping[entry.tick as keyof typeof tickValueMapping] || 0;
        });

        data.push(employeeData);
      });
    }
    // Sort the data by user name in lowercase
    const sortedData = _.sortBy(data, (employeeData) =>
      employeeData.name.toLowerCase()
    );

    return sortedData;
  };

  const transformedData = useMemo(() => {
    return transformData(timesheets, users);
  }, [timesheets, users]);

  const transformedHeader = useMemo(() => {
    const data: any[] = [];
    daysInMonth.forEach((day) => {
      data.push(
        `${dayjs(day).get("date")}  ${Utils.getDayOfWeek(day, "short")}`
      );
    });
    return data;
  }, [daysInMonth]);

  const fullName = [`${t("table.fullName")}`, `${t("table.staffCode")}`];
  const atHome = [`${t("table.workAtHome")}`, `${t("table.totalWorkingDays")}`];

  const dataHeader = [...fullName, ...atHome, ...transformedHeader];

  async function exportToExcel() {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");

    worksheet.getColumn(1).width = 25;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;

    //title
    worksheet.getCell("A1").value = `${t("title.attendanceSheet")} ${
      dayjs(pagination?.startDate).get("month") + 1
    }/${dayjs(pagination?.endDate).get("year")}`;

    if (transformedHeader?.length === 31) worksheet.mergeCells("A1:AI1");
    if (transformedHeader?.length === 30) worksheet.mergeCells("A1:AH1");
    if (transformedHeader?.length === 29) worksheet.mergeCells("A1:AG1");

    worksheet.getCell("A1").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    worksheet.getCell("A1").font = {
      size: 20,
      bold: true,
      color: { argb: "FFFFFF" },
    };

    worksheet.getCell("A1").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "4F81BD" },
    };
    worksheet.getRow(1).height = 30;
    // year

    //header
    worksheet.addRow(dataHeader);

    _.forEach(headerExceljs, (i, index) => {
      if (transformedHeader?.length === 31) {
        worksheet.getCell(`${i}2`).border = {
          top: { style: "thin", color: { argb: "000000" } },
          left: { style: "thin", color: { argb: "000000" } },
          bottom: { style: "thin", color: { argb: "000000" } },
          right: { style: "thin", color: { argb: "000000" } },
        };
      }
      if (transformedHeader?.length === 30) {
        if (index < 34) {
          worksheet.getCell(`${i}2`).border = {
            top: { style: "thin", color: { argb: "000000" } },
            left: { style: "thin", color: { argb: "000000" } },
            bottom: { style: "thin", color: { argb: "000000" } },
            right: { style: "thin", color: { argb: "000000" } },
          };
        }
      }
      if (transformedHeader?.length === 29) {
        if (index < 33) {
          worksheet.getCell(`${i}2`).border = {
            top: { style: "thin", color: { argb: "000000" } },
            left: { style: "thin", color: { argb: "000000" } },
            bottom: { style: "thin", color: { argb: "000000" } },
            right: { style: "thin", color: { argb: "000000" } },
          };
        }
      }
    });

    worksheet.getRow(worksheet.rowCount).height = 25;

    worksheet.getRow(worksheet.rowCount).eachCell((cell: any) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B4C6E7" },
      };
      cell.font = { size: 12, bold: true };
    });
    worksheet.getRow(worksheet.rowCount).height = 25;

    worksheet.getRow(worksheet.rowCount).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    //data

    transformedData.forEach((item, index) => {
      const data: any[] = [];
      _.map(daysInMonth, (day) => {
        const matchingDay = _.find(item.days, (dayData: IDayData) =>
          dayjs(dayjs(dayData.date).format("YYYY-MM-DD")).isSame(
            dayjs(day).format("YYYY-MM-DD")
          )
        );
        data?.push(matchingDay?.status);
      });

      //  const newData = [item?.user?.userData?.fullName].concat(data);
      const newData = [
        item?.user?.userData?.fullName,
        item?.user?.staffCode,
        item?.workAtHome,
        item?.totalWorkingDays,
        ...data,
      ];

      worksheet.addRow(newData);

      _.forEach(headerExceljs, (i, key) => {
        if (transformedHeader?.length === 31)
          worksheet.getCell(`${i}${index + 3}`).border = {
            top: { style: "thin", color: { argb: "000000" } },
            left: { style: "thin", color: { argb: "000000" } },
            right: { style: "thin", color: { argb: "000000" } },
            bottom: { style: "thin", color: { argb: "000000" } },
          };
        if (transformedHeader?.length === 30)
          if (key < 34)
            worksheet.getCell(`${i}${index + 3}`).border = {
              top: { style: "thin", color: { argb: "000000" } },
              left: { style: "thin", color: { argb: "000000" } },
              right: { style: "thin", color: { argb: "000000" } },
              bottom: { style: "thin", color: { argb: "000000" } },
            };
        if (transformedHeader?.length === 29)
          if (key < 33)
            worksheet.getCell(`${i}${index + 3}`).border = {
              top: { style: "thin", color: { argb: "000000" } },
              left: { style: "thin", color: { argb: "000000" } },
              right: { style: "thin", color: { argb: "000000" } },
              bottom: { style: "thin", color: { argb: "000000" } },
            };
      });

      worksheet.getColumn(1).alignment = {
        vertical: "middle",
        horizontal: "left",
      };

      worksheet.getRow(worksheet.rowCount).eachCell((cell: any) => {
        cell.font = { size: 12, bold: false, color: { argb: "233E7C" } };
      });
      worksheet.getRow(worksheet.rowCount).height = 25;
      for (let i = 2; i < dataHeader?.length + 1; i++) {
        worksheet.getColumn(i).alignment = {
          vertical: "middle",
          horizontal: "center",
        };
      }
    });
    //note
    worksheet.addRow([""]);
    worksheet.addRow([""]);
    worksheet.addRow([`${t("label.note")}`]);
    worksheet.getRow(worksheet.rowCount).eachCell((cell) => {
      cell.font = { size: 12, bold: true, color: { argb: "FF0000" } };
    });
    worksheet.getRow(worksheet.rowCount).height = 25;

    worksheet.getRow(worksheet.rowCount).alignment = {
      vertical: "middle",
      horizontal: "left",
    };

    worksheet.addRow([`X:${t("label.workingDay")}`]);

    worksheet.getRow(worksheet.rowCount).eachCell((cell) => {
      cell.font = { size: 12, color: { argb: "000000" } };
    });
    worksheet.getRow(worksheet.rowCount).height = 25;

    worksheet.getRow(worksheet.rowCount).alignment = {
      vertical: "middle",
      horizontal: "left",
    };
    worksheet.addRow([`K/2: ${t("label.aHalfOfUnpaidLeav")}`]);
    worksheet.getRow(worksheet.rowCount).eachCell((cell) => {
      cell.font = { size: 12, color: { argb: "000000" } };
    });
    worksheet.getRow(worksheet.rowCount).height = 25;

    worksheet.getRow(worksheet.rowCount).alignment = {
      vertical: "middle",
      horizontal: "left",
    };
    worksheet.addRow([`K: ${t("label.unpaidLeave")}`]);
    worksheet.getRow(worksheet.rowCount).eachCell((cell) => {
      cell.font = { size: 12, color: { argb: "000000" } };
    });
    worksheet.getRow(worksheet.rowCount).height = 25;

    worksheet.getRow(worksheet.rowCount).alignment = {
      vertical: "middle",
      horizontal: "left",
    };
    worksheet.addRow([`P/2:${t("label.aHalfOfAnnualLeave")}`]);
    worksheet.getRow(worksheet.rowCount).eachCell((cell) => {
      cell.font = { size: 12, color: { argb: "000000" } };
    });
    worksheet.getRow(worksheet.rowCount).height = 25;

    worksheet.getRow(worksheet.rowCount).alignment = {
      vertical: "middle",
      horizontal: "left",
    };
    worksheet.addRow([`P:${t("label.annualLeave")}`]);
    worksheet.getRow(worksheet.rowCount).eachCell((cell) => {
      cell.font = { size: 12, color: { argb: "000000" } };
    });
    worksheet.getRow(worksheet.rowCount).height = 25;

    worksheet.getRow(worksheet.rowCount).alignment = {
      vertical: "middle",
      horizontal: "left",
    };
    worksheet.addRow([`L: ${t("label.holiday")}`]);
    worksheet.getRow(worksheet.rowCount).eachCell((cell) => {
      cell.font = { size: 12, color: { argb: "000000" } };
    });
    worksheet.getRow(worksheet.rowCount).height = 25;

    worksheet.getRow(worksheet.rowCount).alignment = {
      vertical: "middle",
      horizontal: "left",
    };
    worksheet.addRow([`TV:${t("label.probationaryDay")}`]);
    worksheet.getRow(worksheet.rowCount).eachCell((cell) => {
      cell.font = { size: 12, color: { argb: "000000" } };
    });
    worksheet.getRow(worksheet.rowCount).height = 25;

    worksheet.getRow(worksheet.rowCount).alignment = {
      vertical: "middle",
      horizontal: "left",
    };

    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), "attendance-sheet.xlsx");
  }

  return (
    <Button
      size="sm"
      onClick={exportToExcel}
      leftIcon={<Icon as={DownloadIcon} />}
      isDisabled={_.isEmpty(transformedData)}
      sx={{
        background: "rgba(187, 113, 84)",
        "&:hover": {
          background: "rgba(187, 113, 84,0.5)",
        },
        "&:hover:disabled": {
          background: "rgba(187, 113, 84,0.5)",
        },
        color: "#ffffff",
      }}
    >
      {t("button.export")}
    </Button>
  );
};
export default ExportExcelAttdance;
